import { useContext, useEffect } from 'react';

import { HeadSEO } from 'components/base';
import { i18nContext } from 'context/i18n';

import { getDomainValue } from 'utils/domain';

const imageProps = getDomainValue({
  camper: {
    src: '/assets-new/logo.jpg',
    alt: 'Camper logo',
    height: 76,
    width: 150,
  },
  nnormal: {
    src: '/assets-new/logo-nnormal.svg',
    alt: 'Nnormal logo',
    height: 76,
  },
  camperlab: {
    src: '/assets-new/svg/camperlab/camperlab-logo.svg',
    alt: 'Camperlab logo',
    width: 62,
  },
});

const InternalErrorPage = () => {
  const { t } = useContext(i18nContext);
  useEffect(() => {
    const hash = window && window.location.hash ? window.location.hash : null;
    if (window.location.pathname.includes('/content/') && hash) {
      console.log('Hash doesnt exists');
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
      window.location.reload();
    }
  }, []);
  return (
    <div className="lab:min-h-screen lab:bg-clear lab:pt-10">
      <HeadSEO
        title={getDomainValue({
          camper: 'Camper',
          nnormal: 'Nnormal',
          camperlab: 'Camperlab',
        })}
        description={getDomainValue({
          camper: 'Camper 500 error page',
          nnormal: 'Nnormal 500 error page',
          camperlab: 'Camperlab 500 error page',
        })}
      />
      <header className="bg-clear pt-1.5 text-center">
        <img alt="Error Image" className="scale-50 lab:scale-125" {...imageProps} />
      </header>
      <aside className="text-center">
        <img className="h-auto max-sm:w-full" src="/assets-new/error500.gif" alt="Error 500" width={500} height={500} />
      </aside>
      <p className="m-auto max-w-96 p-2.5 pt-1.5 font-primary text-base">{t('generico', 'error.500.titulo', 'WE’RE IN A TANGLE')}</p>
      <p className="m-auto max-w-96 p-2.5 pt-1.5 font-primary text-base">
        {t(
          'generico',
          'error.500.descripcion',
          'Our server has developed some unexpected knots (that’s what happens when you work with so many lace-up shoes). Could you come back later to see if our engineers have managed to unravel them? Thank you; we’re sorry for the inconvenience',
        )}
      </p>
    </div>
  );
};

InternalErrorPage.i18nPage = 'InternalErrorPage';

export default InternalErrorPage;
