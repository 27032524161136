/**
 *  Seguramente hay alguna manera de inferir el valor de retorno de getDomainValue, pero no lo he encontrado en este momento. De todas maneras no es necesario para que funcione; pero si deseable a futuro.
 * */

type Key = 'camper' | 'nnormal' | 'camperlab';

const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

export function getDomain(): Key {
  if (['camper', 'nnormal', 'camperlab'].includes(currentDomain)) {
    return currentDomain as Key;
  }
  return 'camper';
}

export function getDomainValue<C, N, L>(
  args: {
    camper?: C;
    nnormal?: N;
    camperlab?: L;
  },
  domain = getDomain(),
) {
  let domainToUse = domain;
  if (typeof args[domain] === 'string' && ['camper', 'nnormal', 'camperlab'].includes(args[domain])) {
    domainToUse = args[domain] as Key;
  }
  return args[domainToUse] ?? null;
}
